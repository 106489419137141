import axios from 'axios'
import { stringify } from 'qs'
// Polyfill Promises for IE and older browsers.
require('es6-promise').polyfill()

/* eslint-disable */
export const homeConst                = window.__MOBILE_HOME_BASE_CONST__
export const homeSlickCarouselURL     = homeConst.homeSlickCarouselURL
export const searchAdvancedURL        = homeConst.searchAdvancedURL
export const searchURL                = homeConst.searchURL
export const novelLastUpdatesListURL  = homeConst.novelLastUpdatesListURL
// export const lastUpdateNovelsPageURL  = homeConst.lastUpdateNovelsPageURL
// export const searchNovelAuto          = homeConst.searchNovelAuto
// export const peopleReadingURL         = homeConst.peopleReadingURL
/* eslint-enable */

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

export function getHomeSlickCarousel (params) {
  const url = `${homeSlickCarouselURL}?${stringify(params)}`
  return axios.get(url)
}

export function searchNovelsAPI (params) {
  const url = `${searchURL}?${stringify(params)}`
  return axios.get(url)
}

export function fetchLastUpdateNovels (params) {
  const url = `${novelLastUpdatesListURL}?${stringify(params)}`
  return axios.get(url)
}

// import axios from 'axios'
// // Polyfill Promises for IE and older browsers.
// require('es6-promise').polyfill()
//
// /* eslint-disable */
// export let forumURL = ''
// export let forumHeadlinesAPI = ''
// export let novelsHot24hAPI = ''
// export let novelsNewRatingAPI = ''
// export let novelsNewCommentsAPI = ''
// export let homeSlickLinksAPI = ''
// export let homeSlickCarouselAPI = ''
// export let searchNovelAutoAPI = ''
// export let searchURL = ''
// export let searchAdvancedURL = ''
// export let feelingLuckyURL = ''
// export let peopleReadingURL = ''
// try {
//   forumURL                  = DJANGO_MOBILE_BASE__FORUM_URL
//   forumHeadlinesAPI         = DJANGO_MOBILE_BASE__FORUM_HEADLINES_URL
//   novelsHot24hAPI           = DJANGO_MOBILE_BASE__NOVELS_HOT_24H_URL
//   novelsNewRatingAPI        = DJANGO_MOBILE_BASE__NOVELS_NEW_RATING_URL
//   novelsNewCommentsAPI      = DJANGO_MOBILE_BASE__NOVELS_NEW_COMMENTS_URL
//   homeSlickLinksAPI         = DJANGO_MOBILE_BASE__NOVELS_HOME_SLICK_LINKS_URL
//   homeSlickCarouselAPI      = DJANGO_MOBILE_BASE__NOVELS_HOME_SLICK_CAROUSEL_URL
//   searchNovelAutoAPI        = DJANGO_MOBILE_BASE__SEARCH_NOVELS_AUTO_URL
//   searchURL                 = DJANGO_MOBILE_BASE__SEARCH_URL
//   searchAdvancedURL         = DJANGO_MOBILE_BASE__SEARCH_ADVANCED_URL
//   feelingLuckyURL           = DJANGO_MOBILE_BASE__FEELING_LUCKY_URL
//   peopleReadingURL          = DJANGO_MOBILE_BASE__PEOPLE_READING_URL
// } catch (_) {
//   forumURL = 'http://localhost:8000/dien-dan/'
//   forumHeadlinesAPI = 'http://localhost:8000/web-api/forum/get-last-update-stories-headlines/'
//   novelsHot24hAPI = 'http://localhost:8000/web-api/novel/get-novels-hot-24h/'
//   novelsNewRatingAPI = 'http://localhost:8000/web-api/novel/get-novels-new-rating/'
//   novelsNewCommentsAPI = 'http://localhost:8000/web-api/novel/get-novels-new-comments/'
//   homeSlickLinksAPI = 'http://localhost:8000/web-api/novel/get-home-slick-links/'
//   homeSlickCarouselAPI = 'http://localhost:8000/web-api/mobile/get-home-slick-carousel/'
//   searchNovelAutoAPI = 'http://localhost:8000/web-api/novel/search-novel-autocomplete/'
//   searchURL = 'http://localhost:8000/tim-kiem/'
//   searchAdvancedURL = 'http://localhost:8000/tim-kiem/nang-cao/'
//   feelingLuckyURL = 'http://localhost:8000/im-feeling-lucky/'
//   peopleReadingURL = 'http://localhost:8000/people-reading/'
// }
// /* eslint-enable */
//
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// axios.defaults.withCredentials = true
//
// export function getForumHeadlines () {
//   const url = `${forumHeadlinesAPI}?limit=10`
//   return axios.get(url)
// }
//
// export function getNovelsHot24h () {
//   const url = `${novelsHot24hAPI}?limit=10`
//   return axios.get(url)
// }
//
// export function getNovelsNewRating () {
//   const url = `${novelsNewRatingAPI}?limit=10`
//   return axios.get(url)
// }
//
// export function getNovelsNewComments () {
//   const url = `${novelsNewCommentsAPI}?limit=10`
//   return axios.get(url)
// }
//
// export function getHomeSlickLinks () {
//   const url = `${homeSlickLinksAPI}`
//   return axios.get(url)
// }
//
// export function getHomeSlickCarousel () {
//   const url = `${homeSlickCarouselAPI}`
//   return axios.get(url)
// }
//
// export function searchNovelAuto (searchText, exact = false) {
//   let url = `${searchNovelAutoAPI}?q=${searchText}`
//   if (exact) {
//     url = `${searchNovelAutoAPI}?q=${searchText}&exact=yes`
//   }
//   return axios.get(url)
// }
//
// // export function submitReact (params) {
// //   const params_ = Object.assign({ novelId }, params)
// //   const url = `${submitReactAPI}`
// //   return axios.post(url, params_)
// // }
