import type { L10nsStrings } from 'react-timeago/lib/formatters/buildFormatter'

// Vietnamese
// eslint-disable-next-line
export const viStrings: L10nsStrings = {
  prefixAgo: '',
  prefixFromNow: null,
  suffixAgo: null,
  suffixFromNow: 'trước',
  seconds: 'chưa đến 1 phút',
  minute: '1 phút',
  minutes: '%d phút',
  hour: '1 tiếng',
  hours: '%d tiếng',
  day: '1 ngày',
  days: '%d ngày',
  month: '1 tháng',
  months: '%d tháng',
  year: '1 năm',
  years: '%d năm',
  wordSeparator: ' '
}

export const getSmileByName = (name) => {
  const obj = {
    'L': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/like.svg',
    'S': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/smile.svg',
    'O': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/love.svg',
    'F': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/sweet.svg',
    'H': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/lol.svg',
    'B': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/lol2.svg',
    'C': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/cheers.svg',
    'U': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/sun.svg',
    'V': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/victory.svg',
    'M': 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/confused.svg'
  }
  return obj[name]
}

export const smileList = [
  { name: 'L', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/like.svg' },
  { name: 'S', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/smile.svg' },
  { name: 'O', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/love.svg' },
  { name: 'F', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/sweet.svg' },
  { name: 'H', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/lol.svg' },
  { name: 'B', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/lol2.svg' },
  { name: 'C', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/cheers.svg' },
  { name: 'U', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/sun.svg' },
  { name: 'V', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/victory.svg' },
  { name: 'M', url: 'https://cdnx.truyenyy.com/truyenyy/desktop/smiles/confused.svg' }
]

export const smileDescList = {
  'L': 'Thích',
  'S': 'Cười Tươi',
  'O': 'Yêu Lắm',
  'F': 'Cưng Quá',
  'H': 'Haha',
  'B': 'Blehh',
  'C': 'Chúc Mừng',
  'U': 'Mặt Trời',
  'V': 'Chiến Thắng',
  'M': 'Chóng Mặt'
}

export const popModifiersSettings = {
  flip: {
    enabled: false
  },
  preventOverflow: {
    escapeWithReference: true
  }
}
