import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import HomeSlickSliders from './HomeSlickSliders'
// import ForumHeadlines from './ForumHeadlines'
import SlickCarousel from './SlickCarousel'
import Search from './Search'
import LastChapterUpdates from './LastChapterUpdates'
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  //   require('bootstrap/dist/css/bootstrap.min.css');
}

// const homeSlickSlidersRoot = document.getElementById('react-root_mobile-base_home-slick-sliders')
// if (homeSlickSlidersRoot) {
//   ReactDOM.render(<HomeSlickSliders />, homeSlickSlidersRoot)
// }

// const forumHeadlinesRoot = document.getElementById('react-root_mobile-base_forum-headlines')
// if (forumHeadlinesRoot) {
//   ReactDOM.render(<ForumHeadlines />, forumHeadlinesRoot)
// }
//
const homeSlickCarouselRoot = document.getElementById('homeSlickCarousel')
if (homeSlickCarouselRoot) {
  ReactDOM.render(<SlickCarousel />, homeSlickCarouselRoot)
}

const homeSearchRoot = document.getElementById('homeSearch')
if (homeSearchRoot) {
  ReactDOM.render(<Search />, homeSearchRoot)
}

const homeNovelLastUpdatesRoot = document.getElementById('homeNovelLastUpdates')
if (homeNovelLastUpdatesRoot) {
  ReactDOM.render(<LastChapterUpdates />, homeNovelLastUpdatesRoot)
}
