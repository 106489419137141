import React from 'react'
import ContentLoader from 'react-content-loader'

export const ForumHeadlinesLoader = props => (
  <ContentLoader
    width={900}
    height={120}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <circle cx='65' cy='40' r='40' />
    <rect x='125' y='10' rx='4' ry='4' width='600' height='30' />
    <rect x='125' y='60' rx='4' ry='4' width='400' height='30' />
    <rect x='0' y='110' rx='4' ry='4' width='850' height='1' />
  </ContentLoader>
)

export const SlickSliderLoader = props => (
  <ContentLoader
    width={100}
    height={170}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='0' ry='0' width='80' height='120' />
    <rect x='0' y='135' rx='2' ry='2' width='70' height='10' />
    <rect x='0' y='150' rx='4' ry='4' width='50' height='10' />
  </ContentLoader>
)

export const SearchResultsLoader = props => (
  <ContentLoader
    width={500}
    height={80}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='4' ry='4' width='500' height='20' />
    <rect x='0' y='30' rx='4' ry='4' width='400' height='10' />
    <rect x='0' y='50' rx='4' ry='4' width='250' height='10' />
  </ContentLoader>
)

export const LastUpdatesMobileLoader = props => (
  <ContentLoader
    width={500}
    height={66}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='2' ry='2' width='260' height='20' />
    <rect x='280' y='0' rx='2' ry='2' width='50' height='20' />
    <rect x='0' y='30' rx='2' ry='2' width='150' height='14' />
  </ContentLoader>
)
