import React, { Component } from 'react'
import { getHomeSlickCarousel } from './apiRequests'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import './css/SlickCarousel.scss'
import { PulseLoader, BeatLoader } from 'react-spinners'
import { css } from '@emotion/core'

class SlickCarousel extends Component {
  state = {
    items: null
  }

  componentWillMount () {
    getHomeSlickCarousel().then(res => {
      this.setState({
        items: res.data
      })
    })
  }

  render () {
    const { items } = this.state

    const sliderPaging = (i) => {
      return (
        <span className='paginationBullet' id={`dot-${items[i].id}`} />
      )
    }

    const sliderSettings = {
      mobileFirst: true,
      swipeToSlide: true,
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      lazyLoad: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,
      centerMode: false,
      cssEase: 'linear',
      nextArrow: null,
      prevArrow: null,
      customPaging: sliderPaging,
      dotsClass: 'slickDots'
    }

    const loaderCss = css`
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `

    return (
      <div className='SlickCarousel'>
        {!items && (
          <div style={{ height: 160, width: '100%' }}>
            <BeatLoader
              css={loaderCss}
              sizeUnit='px'
              size={18}
              color='#ed1c24'
              loading={!items}
            />
          </div>
        )}
        {items && (
          <Slick {...sliderSettings}>
            {items.map(item => (
              <a href={item.url} key={item.id} aria-label={item.novel} title={item.novel}>
                <img
                  src={item.pictureUrl}
                  alt={item.novel}
                  className='img'
                />
              </a>
            ))}
          </Slick>
        )}
      </div>
    )
  }
}

export default SlickCarousel
