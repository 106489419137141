import React, { Component } from 'react'
import { ConfigProvider, Drawer, Input, Form, Button } from 'antd'
import { List } from 'antd-mobile'
// import debounce from 'lodash/debounce'
import './css/HomeSearch.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { searchAdvancedURL, searchNovelsAPI } from './apiRequests'

const ListItem = List.Item

const layout = {
  // labelCol: { span: 0 },
  wrapperCol: { span: 24 }
}

class SearchNovel extends Component {
  constructor (props) {
    super(props)
    this.form = null
  }

  formRef = React.createRef()

  state = {
    showDrawer: false,
    valueSearch: '',
    dataList: [],
    paginationList: {}
  }

  handleReset = () => {
    this.formRef.current.resetFields()
  }

  handleShowDrawer = (status) => {
    if (status === false) {
      this.setState({
        showDrawer: status,
        valueSearch: '',
        dataList: [],
        paginationList: {}
      })
      this.handleReset()
    } else {
      this.setState({
        showDrawer: status
      })
    }
  }

  fetchNovelData = () => {
    console.log('chạy vào đây')
    const { valueSearch, dataList, paginationList } = this.state
    searchNovelsAPI({
      q: valueSearch,
      page: paginationList.current + 1
    }).then(res => {
      this.setState({
        dataList: dataList.concat(res.data.results),
        paginationList: res.data.pagination
      })
    })
  }

  handleFinish = (values) => {
    this.state.valueSearch = values.q
    this.forceUpdate()
    searchNovelsAPI({
      q: values.q
    }).then(res => {
      this.setState({
        dataList: res.data.results,
        paginationList: res.data.pagination
      })
    })
  }

  render () {
    const { showDrawer, dataList, paginationList } = this.state
    return (
      <ConfigProvider prefixCls='vo-design'>
        <div style={{ backgroundColor: '#ededed', padding: 8 }} className='homeSearchWrapper'>
          <Button
            block
            type='default'
            className='btnShowSearch'
            onClick={() => { this.handleShowDrawer(true) }}
          >
            Tìm Kiếm Truyện, Tác Giả
          </Button>
          <Drawer
            title={
              <>
                Tìm Kiếm Truyện
              </>
            }
            placement='bottom'
            onClose={() => { this.handleShowDrawer(false) }}
            visible={showDrawer}
            height='80vh'
          >
            <div>
              <Form ref={this.formRef} name='control-ref' onFinish={this.handleFinish} layout='inline'>
                <Form.Item {...layout} name='q' style={{ flex: 1 }}>
                  <Input
                    placeholder='Tên truyện, tác giả...'
                    allowClear
                  />
                </Form.Item>
                <Form.Item style={{ flexBasic: '100px', marginRight: 0 }}>
                  <Button type='primary' htmlType='submit'>
                    Tìm Kiếm
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {this.state.valueSearch !== '' && (
              <div style={{ marginTop: 16 }}>
                Tìm với từ khóa <b>"{this.state.valueSearch}"</b>
              </div>
            )}
            <List style={{ marginTop: 16, height: '50vh', overflow: 'auto' }} id='novelSearchResultList'>
              {dataList && dataList.length > 0 && (
                <InfiniteScroll
                  dataLength={dataList.length}
                  next={this.fetchNovelData}
                  hasMore={paginationList.current !== paginationList.numPages}
                  loader={<div className='text-info' style={{ margin: '0 24px' }}>Loading...</div>}
                  endMessage={
                    <p><em className='text-muted' style={{ margin: '0 24px' }}>~ cuối danh sách ~</em></p>
                  }
                  scrollableTarget='novelSearchResultList'
                >
                  {dataList.map(item => (
                    <ListItem key={item.title}>
                      <a href={item.url} style={{ color: '#1C5DED' }}>{item.title}</a>
                      {item.user && (
                        <div style={{ fontSize: 12 }}>{item.user.name}</div>
                      )}
                    </ListItem>
                  ))}
                </InfiniteScroll>
              )}
              {dataList && dataList.length === 0 && (
                <ListItem>Hiện chưa có kết quả</ListItem>
              )}
            </List>
            <div style={{ margin: '16px 0' }}>
              <a href={`${searchAdvancedURL}?q=${this.state.valueSearch}`}>
                <Button
                  type='default'
                  block
                >
                  Tìm Kiếm Nâng Cao
                </Button>
              </a>
            </div>
          </Drawer>
        </div>
      </ConfigProvider>
    )
  }
}

export default SearchNovel
