import React, { Component } from 'react'
import { fetchLastUpdateNovels } from './apiRequests'
import moment from 'moment'
import 'moment/locale/vi'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { LastUpdatesMobileLoader } from './Loaders'
import { viStrings } from './utils'
import { List } from 'antd-mobile'
import ReactTooltip from 'react-tooltip'

import VisibilitySensor from 'react-visibility-sensor'
import './css/LastChapterUpdates.scss'

moment.locale('vi')
const formatter = buildFormatter(viStrings)
const dateFormat = 'h:mm Do MMMM YYYY'

const ListItem = List.Item

class LastUpdates extends Component {
  state = {
    items: null,
    cSelected: [],
    shouldBeShown: false
  }

  loadData = () => {
    fetchLastUpdateNovels().then(res => {
      this.setState({
        items: res.data
      })
      ReactTooltip.rebuild()
    })
  }

  handleOnVisible = (isVisible) => {
    if (!this.state.shouldBeShown && isVisible) {
      this.loadData()
    }
    if (isVisible) {
      this.setState({
        shouldBeShown: true
      })
    }
  }

  render () {
    const { items } = this.state

    return (
      <VisibilitySensor onChange={(isVisible) => this.handleOnVisible(isVisible)}>
        <div style={{ marginTop: 16 }}>
          {!items && (
            <>
              <LastUpdatesMobileLoader />
              <LastUpdatesMobileLoader />
              <LastUpdatesMobileLoader />
            </>
          )}
          {items && (
            <List>
              {items.map(item => (
                <ListItem
                  key={item.id}
                  extra={
                    <div style={{ fontSize: 10 }}>
                      <TimeAgo date={item.updatedAt} formatter={formatter} data-tip={moment(item.updatedAt).format(dateFormat)} />
                    </div>
                  }
                >
                  {item.lastChapter && (
                    <>
                      <a href={item.lastChapter.url} style={{ fontSize: '0.875rem', color: '#056bf1' }}>Chương {item.lastChapter.chapNum}: {item.lastChapter.chapName}</a>
                      <p style={{ fontSize: 12, marginBottom: 0 }}>{item.name}</p>
                    </>
                  )}

                  {!item.lastChapter && (
                    <div><a href={item.url} title={item.name} style={{ fontSize: '0.875rem' }}>{item.name}</a></div>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </VisibilitySensor>
    )
  }
}

export default LastUpdates
